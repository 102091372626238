const list = [
  {
    title: " خبرة واسعة في السوق العقاري",
    detailes:
      " تعتبر شركة أوشن فيو العقارية لديها خبرة كبيرة في صناعة العقارات، حيث تعمل في هذا المجال منذ فترة طويلة. وبفضل خبرائها المحترفين، توفر الشركة النصائح القيمة والمعلومات الدقيقة للعملاء.",
  },
  {
    title: "مجموعة واسعة من الخدمات",
    detailes:
      "تقدم شركة أوشن فيو العقارية مجموعة واسعة من الخدمات العقارية مثل شراء وبيع وإيجار العقارات السكنية والتجارية. بغض النظر عن احتياجاتك العقارية، يمكن أن تساعدك الشركة في تحقيق أهدافك.",
  },
  {
    title: "الاحترافية والأمانة",
    detailes:
      "تعتبر الاحترافية والأمانة أولوية لدى شركة أوشن فيو العقارية. فهي تضمن الحصول على صفقات عادلة وشفافة، وتعمل بمصلحة العميل الأولى. كما أنها تلتزم بمعايير الأخلاق المهنية العالية في جميع جوانب العمل.",
  },
  {
    title: "شبكة واسعة من العملاء والشركاء",
    detailes:
      "تتمتع شركة أوشن فيو العقارية بشبكة واسعة من العملاء والشركاء، مما يعزز احتمالات تحقيق النجاح في العمل العقاري. تستخدم الشركة تكنولوجيا حديثة للتواصل مع العملاء وجمع المعلومات اللازمة، مما يسهل على العملاء العثور على العقار المناسب.",
  },
  {
    title: " خدمة العملاء الممتازة",
    detailes:
      "تولي شركة أوشن فيو العقارية أهمية كبيرة لتجربة العملاء. فهي تقدم خدمة عملاء ممتازة وتهتم بتلبية احتياجات ومتطلبات العملاء",
  },
  {
    title: "فريق محترف ومتفاني",
    detailes:
      "تستخدم شركة أوشن فيو العقارية فريقًا من المحترفين المؤهلين والمتفانين، الذين يفهمون احتياجاتك ويعملون بجد لتلبيتها. سوف يتعاونون معك خلال كل خطوة من خطوات العملية العقارية لضمان رضاك التام.",
  },
  {
    title: "رضا العملاء الممتاز",
    detailes:
      "تعتبر رضا العملاء أولوية قصوى لشركة أوشن فيو العقارية. قامت الشركة ببناء سمعتها على تقديم خدمات عالية الجودة وتجربة عملاء ممتازة. قم بقراءة بعض المراجعات أو الشهادات لعملاء",
  },
];

export const WhyUs = () => {
  return (
    <div id="whyUs" class=" bg-gray-900">
      <div className="mx-auto pt-24 pb-10 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
          <h2 class="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
            لماذا نحن؟
          </h2>
        </div>
        <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-xl leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {list.map((item) => {
            return (
              <div>
                <dt class="font-semibold text-gray-100">{item.title} </dt>
                <dd class="mt-1 text-gray-400">{item.detailes}</dd>
              </div>
            );
          })}
        </dl>
      </div>
    </div>
  );
};
